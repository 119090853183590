import Menu from './components/Menu';
import React, { useEffect } from 'react';
import { IonApp, IonPage,IonContent,IonGrid,isPlatform} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
//import './theme/variables.css';
import './App.css';

import WebHeader from './components/WebHeader';
import WebFooter from './components/WebFooter';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './Routes';
import StoreProvider from './store';
import CommonElements from './components/CommonElements';
import CartMenu from './components/CartMenu';

// @ts-ignore
//import  tawkTo from './components/tawkto'; 

import WithClearCache from './WithClearCache';

const App: React.FC = () => {
  const isWeb = ():boolean=>{
    return isPlatform('mobileweb');
  }

  //const tawkToPropertyId = '5d2c4bb9bfcb827ab0cbcd65/1er899sg3'

  useEffect(()=>{
    //tawkTo(tawkToPropertyId)
  },[])

  return (
    <IonApp >
        <WithClearCache/>
        <StoreProvider>
          
            <IonReactRouter>
                <Menu />
                <CartMenu/>
                <IonPage id="main">          
                  <Header/>
                  <IonContent className={isWeb()?'web':'web'}>
                    <IonGrid className="page web-container">
                      <WebHeader />
                        <div className='page-content'>
                          <Routes/>
                        </div>
                        <CommonElements/>  
                      <WebFooter/>
                    </IonGrid>
                  </IonContent>
                  <Footer/>
                </IonPage>
            </IonReactRouter>
          
        </StoreProvider>
      
    </IonApp>
  );
};

export default App;