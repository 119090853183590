import React, { useEffect, useState }  from 'react';
import {IonButton,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent} from '@ionic/react';

    import YouTube from 'react-youtube';

const VideoPlayer:React.FC<any> = (props:any)=>{
    
    const opts:any = {
        //height: '390',
        width: '100%',
        playerVars: {
          autoplay: 1,
        }
    }
    const _onReady =(event:any) =>{
        // access to player in all event handlers via event.target
        //event.target.pauseVideo();
    }
    
    return (
        <IonModal isOpen={props.params.show} backdropDismiss={false} cssClass="player-modal">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Video</IonTitle>
                    <IonButtons slot="end">
                        <IonButton slot="end" onClick={()=>{props.hide()}}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="search-modal">
                <YouTube videoId={props.params.videoId} key={props.params.videoId} opts={opts}/>
            </IonContent>
        </IonModal>  
    )
}

export default VideoPlayer;